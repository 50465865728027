import classNames from 'classnames'
import Image from 'next/image'
import { CSSProperties } from 'react'
import { cloudinaryAspectRatioCropLoader } from './loaders/cloudinary'
import styles from './styles.module.scss'

export type ResponsiveImageProps = {
  priority?: boolean
  className?: string
  alt: string
  style?: CSSProperties
  src: string
  suffix?: string
  width: number
  height: number
  sizes?: string
  aspectRatio?: { width: number; height: number }
  layout?: 'responsive' | 'fixed' | 'fill' | 'intrinsic'
  loader?: (args) => string
  unoptimized?: boolean
  containerClassName?: string
  isZoomed?: boolean
  onClick?: (event: any) => void
  onPointerMove?: (event: any) => void
  onMouseLeave?: (event: any) => void
  onKeyPress?: (event: any) => void
}

// A wrapper around `next/image` with defaults that make sense for our app
const ResponsiveImage = ({
  priority,
  className,
  containerClassName = '',
  alt,
  style,
  src,
  suffix,
  width,
  height,
  sizes = '(max-width: 480px) 62vw, (min-width: 1000px) 25vw',
  aspectRatio = { width, height },
  layout = 'responsive',
  loader = cloudinaryAspectRatioCropLoader,
  unoptimized = false,
  isZoomed = false,
  ...rest
}: ResponsiveImageProps) => {
  if (!src) {
    return null
  }

  return (
    <div style={style} className={containerClassName}>
      <Image
        className={classNames(styles['responsive-image'], className)}
        loader={args => loader({ ...args, aspectRatio, isZoomed, urlSuffix: suffix })}
        alt={alt}
        src={src}
        layout={layout}
        width={width}
        height={height}
        sizes={sizes}
        priority={priority}
        unoptimized={unoptimized}
        {...rest}
      />
    </div>
  )
}

export default ResponsiveImage
