import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '@everlane/button'

import useFacebookSignIn from 'lib/network/hooks/useFacebookSignIn'
import { publishEvent } from 'lib/events/tracking'
import Image from 'components/core/Image'
import Event from 'types/Event'

import Facebook from './connect'
import styles from './styles.module.scss'

const FACEBOOK_ICON_URL = 'https://media.everlane.com/image/upload/v1698955728/Facebook_Logo.svg'

const FacebookConnectButton = ({ className, siteLocation, iconOnly, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleFail = err => {
    setIsLoading(false)
    onError(err)
  }
  const handleSuccess = ({ session }) => {
    const timestamp = Math.trunc(Date.now() / 1000).toString()

    setIsLoading(false)
    publishEvent(Event.User.SIGN_IN, {
      site_location: siteLocation,
      user: session?.user,
      visitor: session?.visitor,
      timestamp,
    })
    onSuccess()
  }

  const { mutate: signIn } = useFacebookSignIn({ onCompleted: handleSuccess, onError: handleFail })

  const handleClick = () => {
    setIsLoading(true)
    publishEvent(Event.Login.FACEBOOK_BUTTON)

    Facebook.login({ siteLocation, signIn })
  }

  return (
    <Button
      type="button"
      variant={iconOnly ? 'secondary' : 'secondary-icon'}
      icon
      className={classNames(className, styles['facebook-connect__button'])}
      onClick={handleClick}
      disabled={isLoading}
    >
      <div className={styles['facebook-connect__cta']}>
        {!isLoading && (
          <Image
            src={FACEBOOK_ICON_URL}
            alt="Log in with Facebook"
            containerClassName={styles['facebook-connect__cta-image']}
            aspectRatio={{ width: 1, height: 1 }}
            width={18}
            height={18}
            sizes="18px"
          />
        )}
        {!iconOnly && (
          <span className={styles['facebook-connect__cta-text']}>
            {isLoading ? 'Connecting' : 'Facebook'}
          </span>
        )}
      </div>
    </Button>
  )
}

FacebookConnectButton.propTypes = {
  className: PropTypes.string,
  siteLocation: PropTypes.string,
  iconOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default FacebookConnectButton
